import React from 'react';
import {layoutAction} from "../actions";
import {useDispatch} from "react-redux";

const Logo = (props) => {

    const dispatch = useDispatch()
    // const profileMenu = useSelector(state => state?.profileMenu);

    const handleLayoutChange = () => {
        dispatch(layoutAction(false))
    }
    return (
        <img
            alt="Logo"
            src="/static/images/newLogo.svg"
            style={{width: '117px', borderRadius: 3, height: '38.92px'}}
            {...props}
            onClick={handleLayoutChange}
        />
    );
}

export default Logo;